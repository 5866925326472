import React from "react";
import Header from "./header";
import Footer from "./footer";
import "../styles/layout.scss";
import { graphql, useStaticQuery } from "gatsby";

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          description
          title
          copyright
        }
      }
    }
  `);

  const siteMeta = data.site.siteMetadata;

  return (
    <div className="layoutContainer">
      <Header siteMeta={siteMeta} headOneOfPage={props.headOneOfPage} />
      <div className="content"> {props.children}</div>
      <Footer siteMeta={siteMeta} />
    </div>
  );
};

export default Layout;
