import React from "react";
import { Link } from "gatsby";
import * as navStyle from "../styles/nav.module.scss";

const Nav = () => {
  return (
    <nav id={navStyle.navigation}>
      <ul className={navStyle.navBar}>
        <Link
          className={`${navStyle.menuLinks} ${navStyle.leftLink}`}
          activeClassName={navStyle.activeMenu}
          to="/"
        >
          Moringa
        </Link>
        <Link
          className={navStyle.menuLinks}
          activeClassName={navStyle.activeMenu}
          to="/benefits"
        >
          Miért jó
        </Link>
        <div className={navStyle.center} />
        <Link
          className={navStyle.menuLinks}
          activeClassName={navStyle.activeMenu}
          to="/ingredients"
        >
          Tápanyagok
        </Link>
        <Link
          className={`${navStyle.menuLinks} ${navStyle.rightLink}`}
          activeClassName={navStyle.activeMenu}
          to="/moringa-products"
        >
          Termékek
        </Link>

        <Link className={navStyle.centerLink} to="/ "></Link>
      </ul>
    </nav>
  );
};

export default Nav;
