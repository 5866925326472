import React from "react"

import * as footerStyle from "../styles/footer.module.scss" //There is a problem without "* as"

const Footer = ({ siteMeta }) => {
  return (
    <footer className={footerStyle.container}>
      {/* <div className={footerStyle.created}> */}
      <div className={footerStyle.contact}>NETALON Biofalatka Kft.</div>
      <div className={footerStyle.developer}> {siteMeta.copyright}</div>
      <div className={footerStyle.developer}>
        {" "}
        Készítette: {siteMeta.author}
      </div>
      <div className={footerStyle.developer}>
        <a href="mailto: bgzoltan@gmail.com">
          {/* <GoMail className="icon" /> */}
        </a>
      </div>
      {/* </div> */}

      {/* <div className="contact">email: ugyfelszolgalat@okosbazar.hu</div> */}
    </footer>
  )
}

export default Footer
