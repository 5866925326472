import React from "react";
import Nav from "../components/nav";

import * as headerStyle from "../styles/header.module.scss"; //There is a problem without "* as"

const Header = ({ headOneOfPage }) => {
  return (
    <header>
      <h1 className={headerStyle.headOneOfPage}>{headOneOfPage}</h1>
      <Nav />
    </header>
  );
};

export default Header;
